import { TFunction } from 'i18next';

export enum TagValue {
  ITF = 'ITF',
  IN_SECTION = 'IN_SECTION',
  JTT = 'JTT',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  WTN_EXCLUDE = 'WTN_EXCLUDE',
}

export const getCheckboxes = (t: TFunction) => [
  { label: t('itf tournament'), value: TagValue.ITF },
  { label: t('in section override'), value: TagValue.IN_SECTION },
  { label: t('jtt tag'), value: TagValue.JTT },
  { label: t('high school'), value: TagValue.HIGH_SCHOOL },
  { label: t('exclude from wtn'), value: TagValue.WTN_EXCLUDE },
];

export const mutuallyExclusiveTags = [[TagValue.ITF, TagValue.IN_SECTION]];
