// extracted by mini-css-extract-plugin
export var bodyBase = "events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodyLarge = "events-approval-modal-module--body-large--6a17e events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodyLargeBold = "events-approval-modal-module--body-large-bold--21b40 events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodyRegular = "events-approval-modal-module--body-regular--d955d events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodyRegularBold = "events-approval-modal-module--body-regular-bold--a7ae8 events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodySmall = "events-approval-modal-module--body-small--f0164 events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var bodySmallBold = "events-approval-modal-module--body-small-bold--ffb7e events-approval-modal-module--body-base--9d517 events-approval-modal-module--site-font--3ed59";
export var borderTop = "events-approval-modal-module--border-top--2fe9a";
export var breakWordContainer = "events-approval-modal-module--break-word-container--9090b";
export var buttonIconBase = "events-approval-modal-module--button-icon-base--01699";
export var clickLink = "events-approval-modal-module--click-link--e4b3c";
export var dropdownBase = "events-approval-modal-module--dropdown-base--a8d73";
export var dropdownSelectBase = "events-approval-modal-module--dropdown-select-base--f0716 events-approval-modal-module--text-input--31102";
export var emailTemplateContainer = "events-approval-modal-module--email-template-container--5806b";
export var errorIcon = "events-approval-modal-module--error-icon--07ae1";
export var errorText = "events-approval-modal-module--error-text--63047";
export var flexCol = "events-approval-modal-module--flex-col--a5910";
export var formErrorMessage = "events-approval-modal-module--form-error-message--4c637";
export var h3 = "events-approval-modal-module--h3--6843a";
export var h4 = "events-approval-modal-module--h4--32446";
export var hoverLink = "events-approval-modal-module--hover-link--7e87b";
export var hoverRow = "events-approval-modal-module--hover-row--feb4a";
export var membershipContainer = "events-approval-modal-module--membership-container--520e6 events-approval-modal-module--flex-col--a5910 events-approval-modal-module--primary-border--3a119";
export var membershipHeader = "events-approval-modal-module--membership-header--edd47";
export var membershipHeading = "events-approval-modal-module--membership-heading--9ea1f";
export var membershipLabel = "events-approval-modal-module--membership-label--1c982";
export var modalContent = "events-approval-modal-module--modal-content--559e5";
export var moreFiltersBorderClass = "events-approval-modal-module--more-filters-border-class--e75da";
export var optionalMessageBox = "events-approval-modal-module--optional-message-box--9f1cd";
export var pageBg = "events-approval-modal-module--page-bg--20a61";
export var pointer = "events-approval-modal-module--pointer--d7741";
export var primaryBorder = "events-approval-modal-module--primary-border--3a119";
export var shadowBoxLight = "events-approval-modal-module--shadow-box-light--da1e1";
export var siteFont = "events-approval-modal-module--site-font--3ed59";
export var slideDownAndFade = "events-approval-modal-module--slideDownAndFade--6876b";
export var slideLeftAndFade = "events-approval-modal-module--slideLeftAndFade--9b142";
export var slideRightAndFade = "events-approval-modal-module--slideRightAndFade--2d7f8";
export var slideUpAndFade = "events-approval-modal-module--slideUpAndFade--61d33";
export var statusDecoration = "events-approval-modal-module--status-decoration--0c284";
export var textInput = "events-approval-modal-module--text-input--31102";
export var textInverted = "events-approval-modal-module--text-inverted--4cca0";
export var textMediumDark = "events-approval-modal-module--text-medium-dark--14f60";
export var tooltipFont = "events-approval-modal-module--tooltipFont--5dedc";
export var unstyledButton = "events-approval-modal-module--unstyled-button--d27b7";