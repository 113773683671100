import React, { useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import { SizedGridContent, TagDivider } from 'src/components/events/events';
import { ADD_TOURNAMENT_TAG, DELETE_TOURNAMENT_TAG } from 'src/components/events/events-queries';
import { Checkbox } from 'src/components/formik-fields/formik-fields';
import Panel from 'src/components/panel/panel';
import { AddTagToTournament, AddTagToTournamentVariables } from 'src/graphql-types/AddTagToTournament';
import { DeleteTournamentTag, DeleteTournamentTagVariables } from 'src/graphql-types/DeleteTournamentTag';

import { getCheckboxes, mutuallyExclusiveTags } from './utils';

interface TournamentTagsProps {
  tournamentId: string;
  tags: string[];
}

const TournamentTags: React.FC<TournamentTagsProps> = React.memo(({ tournamentId, tags }) => {
  const { t } = useTranslation();
  const checkboxes = useMemo(() => getCheckboxes(t), [t]);

  const [addTournamentTag, { loading: addingTag, error: addingTagError }] = useMutation<
    AddTagToTournament,
    AddTagToTournamentVariables
  >(ADD_TOURNAMENT_TAG, { client: tournamentsClient });

  const [deleteTournamentTag, { loading: deletingTag, error: deletingTagError }] = useMutation<
    DeleteTournamentTag,
    DeleteTournamentTagVariables
  >(DELETE_TOURNAMENT_TAG, { client: tournamentsClient });

  const onCheckedChange = useCallback(
    (tag, isChecked) => {
      const variables = { tournamentId, tag };
      isChecked ? addTournamentTag({ variables }) : deleteTournamentTag({ variables });
    },
    [tournamentId, addTournamentTag, deleteTournamentTag],
  );

  const checked = useMemo(() => {
    const vals: { [key in string]: boolean } = {};
    checkboxes.forEach(({ value: v }) => (vals[v] = tags?.includes(v)));
    return vals;
  }, [tags, checkboxes]);

  const disabled = useMemo(() => {
    const disabled: { [key in string]: boolean } = {};
    checkboxes.forEach(({ value }) => (disabled[value] = false));
    mutuallyExclusiveTags.forEach((exclusiveGroup) => {
      exclusiveGroup.forEach((tag) => {
        if (tags.includes(tag)) {
          exclusiveGroup.forEach((otherTag) => {
            if (tag !== otherTag) {
              disabled[otherTag] = true;
            }
          });
        }
      });
    });
    return disabled;
  }, [tags, checkboxes]);

  return (
    <Grid item xs={12} md={6}>
      <Panel title={t('tags')} spacing={{}}>
        <SizedGridContent>
          {checkboxes.map(({ value, label }, i) => (
            <React.Fragment key={value}>
              <Checkbox
                label={label}
                checked={checked[value]}
                onChecked={(isChecked) => onCheckedChange(value, isChecked)}
                disabled={addingTag || deletingTag || disabled[value]}
              />
              {i !== checkboxes.length - 1 && <TagDivider />}
            </React.Fragment>
          ))}
          {addingTagError && <APIErrorMessage error={addingTagError.message} />}
          {deletingTagError && <APIErrorMessage error={deletingTagError.message} />}
        </SizedGridContent>
      </Panel>
    </Grid>
  );
});

export default TournamentTags;
