// extracted by mini-css-extract-plugin
export var bodyBase = "tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodyLarge = "tournament-info-module--body-large--0c1fc tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodyLargeBold = "tournament-info-module--body-large-bold--b6364 tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodyRegular = "tournament-info-module--body-regular--d26bc tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodyRegularBold = "tournament-info-module--body-regular-bold--89b3f tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodySmall = "tournament-info-module--body-small--8801d tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var bodySmallBold = "tournament-info-module--body-small-bold--ab4cc tournament-info-module--body-base--a13d2 tournament-info-module--site-font--80b56";
export var borderTop = "tournament-info-module--border-top--48b92";
export var breakWordContainer = "tournament-info-module--break-word-container--e1d53";
export var buttonIconBase = "tournament-info-module--button-icon-base--d66ab";
export var clickLink = "tournament-info-module--click-link--4a71b";
export var dropdownBase = "tournament-info-module--dropdown-base--b64a0";
export var dropdownSelectBase = "tournament-info-module--dropdown-select-base--69ace tournament-info-module--text-input--f327b";
export var externalLinkIcon = "tournament-info-module--external-link-icon--44e7a";
export var externalLinkLabel = "tournament-info-module--external-link-label--14ab3";
export var flexCol = "tournament-info-module--flex-col--da853";
export var formErrorMessage = "tournament-info-module--form-error-message--6dea9";
export var h3 = "tournament-info-module--h3--76695";
export var h4 = "tournament-info-module--h4--50133";
export var hoverLink = "tournament-info-module--hover-link--30088";
export var hoverRow = "tournament-info-module--hover-row--59917";
export var membershipContainer = "tournament-info-module--membership-container--d63e7 tournament-info-module--flex-col--da853 tournament-info-module--primary-border--fba63";
export var membershipHeader = "tournament-info-module--membership-header--495dc";
export var membershipHeading = "tournament-info-module--membership-heading--94e30";
export var membershipLabel = "tournament-info-module--membership-label--d6bab";
export var modalContent = "tournament-info-module--modal-content--80551";
export var moreFiltersBorderClass = "tournament-info-module--more-filters-border-class--e54af";
export var pageBg = "tournament-info-module--page-bg--e3b91";
export var panelSubtitle = "tournament-info-module--panel-subtitle--c1903";
export var pointer = "tournament-info-module--pointer--8ad7e";
export var priceForm = "tournament-info-module--price-form--4894b";
export var primaryBorder = "tournament-info-module--primary-border--fba63";
export var shadowBoxLight = "tournament-info-module--shadow-box-light--74f28";
export var siteFont = "tournament-info-module--site-font--80b56";
export var slideDownAndFade = "tournament-info-module--slideDownAndFade--8c982";
export var slideLeftAndFade = "tournament-info-module--slideLeftAndFade--0b04b";
export var slideRightAndFade = "tournament-info-module--slideRightAndFade--d7963";
export var slideUpAndFade = "tournament-info-module--slideUpAndFade--d0697";
export var statusDecoration = "tournament-info-module--status-decoration--e0701";
export var textInput = "tournament-info-module--text-input--f327b";
export var textInverted = "tournament-info-module--text-inverted--fa8ad";
export var textMediumDark = "tournament-info-module--text-medium-dark--3b633";
export var tooltipFont = "tournament-info-module--tooltipFont--b6d81";
export var unstyledButton = "tournament-info-module--unstyled-button--d237e";