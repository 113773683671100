import gql from 'graphql-tag';

export const UPDATE_TOURNAMENT_FEE = gql`
  mutation UpdateTournamentFee($tournamentId: UUID!, $newFee: Int!) {
    updateTournamentFeeOverride(tournamentId: $tournamentId, tournamentFeeOverride: $newFee) {
      id
      tournamentFee
    }
  }
`;

export const UPDATE_TOURNAMENT_VISIBILITY = gql`
  mutation UpdateTournamentVisibility($id: UUID!, $visibility: TournamentVisibilityMask!) {
    updateTournamentVisibilityMask(tournamentId: $id, visibilityMask: $visibility) {
      id
      visibilityMask
    }
  }
`;

export const DELETE_TOURNAMENT = gql`
  mutation DeleteTournament($id: UUID!) {
    deleteTournament(tournamentId: $id, forced: true) {
      id
    }
  }
`;

export const CANCEL_TOURNAMENT = gql`
  mutation CancelTournament($tournamentId: UUID!) {
    cancelTournament(tournamentId: $tournamentId) {
      id
      isCancelled
    }
  }
`;
