import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as styles from 'src/components/events/events.module.less';
import { GetEvents_tournament_events as Event } from 'src/graphql-types/GetEvents';

interface EventDatesProps {
  event: Event;
}

const EventDates: React.FC<EventDatesProps> = ({ event: e }) => {
  const { t } = useTranslation();
  const { hasNonConsecutiveDays, startDate, endDate } = e.timings;

  return (
    <>
      {hasNonConsecutiveDays
        ? t('event date range', {
            dates: { start: dayjs(startDate), end: dayjs(endDate) },
          })
        : t('event start date', { date: dayjs(startDate) })}
      {hasNonConsecutiveDays && <div className={styles.nonConsecutiveLabel}>{t('non consecutive')}</div>}
    </>
  );
};

export default EventDates;
