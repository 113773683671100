import React, { useMemo } from 'react';

import { Trans } from 'react-i18next';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { GetOrganisationMembership } from 'src/utils/classic-api';

interface Props {
  organisationMembership?: GetOrganisationMembership;
  loadingOrganisation: boolean;
}

const MembershipErrorComponent: React.FC<Props> = ({ organisationMembership, loadingOrganisation }) => {
  const isActive = useMemo(() => {
    return organisationMembership?.MembershipStatus === 'Active';
  }, [organisationMembership]);

  const isIndividualTennisBusiness = useMemo(() => {
    return organisationMembership?.OrgType?.Name.toLowerCase() === 'individual tennis business';
  }, [organisationMembership]);
  return (
    <>
      {!loadingOrganisation && organisationMembership && !isActive && (
        <Trans
          i18nKey="organisation membership expired"
          values={{ name: organisationMembership?.Name, client: getClientConfig().clientName }}
          components={{
            site: <a href={getEnvConfig().ORG_MEMBERSHIP_SITE_URL} target="_blank" rel="noreferrer" />,
          }}
        />
      )}
      {!loadingOrganisation && organisationMembership && isIndividualTennisBusiness && (
        <Trans
          i18nKey="organisation not active"
          values={{
            organisation: organisationMembership?.OrgType?.Name,
            client: getClientConfig().clientName,
          }}
        />
      )}
      {!loadingOrganisation && !organisationMembership && (
        <Trans
          i18nKey="no organisation member found"
          values={{ client: getClientConfig().clientName }}
          components={{
            site: <a href={getEnvConfig().ORG_MEMBERSHIP_SITE_URL} target="_blank" rel="noreferrer" />,
          }}
        />
      )}
    </>
  );
};

export default MembershipErrorComponent;
