import React from 'react';

import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import ApprovalButtons from 'src/components/approval-buttons/approval-buttons';
import EventDates from 'src/components/events/events-table/event-dates';
import SanctionStatusLabel from 'src/components/sanction-status-label/sanction-status-label';
import { DrawType, EventName, Surface } from 'src/components/tournament-attributes/tournament-attributes';
import { GetEvents_tournament_events as Event } from 'src/graphql-types/GetEvents';
import { EventTypeEnum } from 'src/graphql-types/globalTournamentTypes';

export const useEventTableColumns = (
  approvalEnabled: boolean,
  approvedStates: Record<string, boolean | undefined>,
  editMode: boolean,
  eventApprovedStates: Record<string, boolean>,
  updateApproval: (id: string, approved: boolean) => void,
  teamEvents: boolean,
) => {
  const { t } = useTranslation();
  const SCORE_FORMAT = 'score format';

  const cols: DataCols<Event> = [
    {
      key: 'event',
      title: t('division'),
      getValue: (e) => <EventName event={e} />,
    },
    {
      key: 'dates',
      title: t('date(s)'),
      getValue: (e) => <EventDates event={e} />,
    },
    {
      key: 'days',
      title: t('days'),
      getValue: (e) => e.timings.lengthInDays,
    },
    {
      key: 'cost',
      title: t('cost'),
      getValue: ({ pricing: { entryFee: p } }) => `${t('currency sign')}${(p.amount / 100).toFixed(2)}`,
    },
    {
      key: 'draw type',
      title: t('draw type'),
      getValue: (e) => <DrawType event={e} />,
    },
    {
      key: SCORE_FORMAT,
      title: t(SCORE_FORMAT),
      getValue: (e) =>
        e.formatConfiguration?.scoreFormat
          ? t(`tods ${e.formatConfiguration?.scoreFormat.replace(/:/g, '-')}`)
          : t('n/a'),
    },
    {
      key: 'draw',
      title: t('entries limit'),
      getValue: (e) =>
        e.division.eventType === EventTypeEnum.TEAM ? t('n/a') : e.formatConfiguration?.drawSize ?? t('no limit'),
    },
    {
      key: 'surface',
      title: t('surface'),
      getValue: (e) => <Surface event={e} />,
    },
  ];

  if (approvalEnabled) {
    cols.push({
      key: 'buttons',
      title: t('approve or decline'),
      getValue: ({ id }) => (
        <ApprovalButtons
          id={id}
          approved={approvedStates[id]}
          disableDecline={editMode && eventApprovedStates[id]}
          updateApproval={(approved) => updateApproval(id, !!approved)}
        />
      ),
    });
  } else {
    cols.push({
      key: 'status',
      title: t('status'),
      getValue: (e) => <SanctionStatusLabel status={e.sanctionStatus} />,
    });
  }
  return teamEvents ? cols.filter((c) => c.key !== SCORE_FORMAT) : cols;
};
