import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Events from 'src/components/events/events';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EventsPage = () => {
  return (
    <Router basepath="/tournaments">
      <EventsRoute path="/:tournamentId" />
    </Router>
  );
};

interface EventsRouteProps extends RouteComponentProps {
  tournamentId?: string;
}

const EventsRoute: React.FC<EventsRouteProps> = ({ tournamentId }) => {
  return (
    <Layout>
      <SEO title="Events" />
      <PrivateRoute>
        <Events tournamentId={tournamentId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EventsPage;
